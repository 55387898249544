import {combineReducers} from "redux";
import {reducer as formReducer} from "redux-form";
import {registerSelectors} from "reselect-tools";
import {reducer as appReducer, selectors as appSelectors} from "../App/slice";
import {
  reducer as beneProposalsReducer,
  selectors as beneProposalsSelectors,
} from "../BeneProposals/slice";
import {reducer as capsReducer} from "../Caps/reducers";

import * as capsSelectors from "../Caps/selectors";
import {
  reducer as compositionsReducer,
  selectors as compositionsSelectors,
} from "../Compositions/slice";
import {
  reducer as elementaryClassesReducer,
  selectors as elementaryClassesSelectors,
} from "../ElementaryClasses/slice";
import {reducer as eSignReducer} from "../ESign/reducers";
import * as eSignSelectors from "../ESign/selectors";
import {yn} from "../helpers/yn";
import {reducer as proposalsReducer} from "../Proposals/reducers";
import * as proposalsSelectors from "../Proposals/selectors";
import {reducer as ticketsReducer} from "../Tickets/reducers";
import * as ticketsSelectors from "../Tickets/selectors";
import {reducer as userReducer} from "../User/reducers";
import * as userSelectors from "../User/selectors";
import {reducer as usersReducer} from "../Users/reducers";
import * as usersSelectors from "../Users/selectors";

export const rootReducer = combineReducers({
  app: appReducer,
  beneProposals: beneProposalsReducer,
  caps: capsReducer,
  compositions: compositionsReducer,
  elementaryClasses: elementaryClassesReducer,
  eSign: eSignReducer,
  form: formReducer,
  proposals: proposalsReducer,
  tickets: ticketsReducer,
  user: userReducer,
  users: usersReducer,
});

export type IRootState = ReturnType<typeof rootReducer>;

if (yn(process.env.REACT_APP_ENABLE_DEV_TOOLS)) {
  // Reselect debug
  registerSelectors(appSelectors);
  registerSelectors(beneProposalsSelectors);
  registerSelectors(capsSelectors);
  registerSelectors(compositionsSelectors);
  registerSelectors(elementaryClassesSelectors);
  registerSelectors(eSignSelectors);
  registerSelectors(proposalsSelectors);
  registerSelectors(ticketsSelectors);
  registerSelectors(userSelectors);
  registerSelectors(usersSelectors);
}
