import {schema} from "normalizr";
import {capSchema, ICapDB, ICapsEntities, IInvestmentFund} from "../Caps/types";
import {entityUtilityFactory} from "../helpers/entityUtilityFactory";
import {parseISO} from "../types/dates";
import {IUserDB, IUsersEntities, userSchema} from "../Users/types";

export interface ICompositionDB {
  cap: ICapDB;
  comment?: string;
  contractor: IUserDB;
  date: string;
  dateAccept?: string;
  dateDelivery?: string;
  esignId?: string;
  id: string;
  jsonDocs?: string;
  jsonFunds: string;
  profitCenter: string;
  status: CompositionStatus;
  user: IUserDB;
  userEsignId?: string;
}

export interface ICompositionEntity {
  cap: string;
  comment?: string;
  contractor: string;
  date: string;
  dateAccept?: string;
  dateDelivery?: string;
  esignId?: string;
  id: string;
  jsonDocs?: string;
  jsonFunds: string;
  profitCenter: string;
  status: CompositionStatus;
  user: string;
  userEsignId?: string;
}

export interface IComposition {
  cap: string;
  comment?: string;
  contractor: string;
  date: Date;
  dateAccept?: Date;
  dateDelivery?: Date;
  esignId?: string;
  id: string;
  docs?: IDocs;
  funds: IFunds;
  profitCenter: string;
  status: CompositionStatus;
  user: string;
  userEsignId?: string;
}

export enum CompositionStatus {
  Enabled = "attivo",
  Disabled = "inattivo",
}

export interface SelectedFund {
  id: string;
  percent: string;
}

export interface IFunds {
  funds: IInvestmentFund[];
  selectedFunds: SelectedFund[];
}

interface IDocs {}

export interface ICompositionParams {
  limit: number;
  offset: number;
  sort?: string;
}

const compositionSchema = new schema.Entity("compositions", {
  cap: capSchema,
  contractor: userSchema,
  user: userSchema,
});

export interface ICompositionsEntities {
  [key: string]: ICompositionEntity;
}

export type EntityShape = {
  compositions: ICompositionsEntities;
  caps: ICapsEntities;
  users: IUsersEntities;
};

const compositionParse = (compositionGeneric: ICompositionEntity) => {
  const {date, dateAccept, dateDelivery, jsonDocs, jsonFunds, ...rest} =
    compositionGeneric;
  const composition: IComposition = {
    ...rest,
    date: parseISO(date),
    dateAccept: dateAccept ? parseISO(dateAccept) : undefined,
    dateDelivery: dateDelivery ? parseISO(dateDelivery) : undefined,
    funds: JSON.parse(jsonFunds) as IFunds,
    docs: jsonDocs ? (JSON.parse(jsonDocs) as IDocs) : undefined,
  };

  return composition;
};

const compositionstringify = (compositionGeneric: IComposition) => {
  const {date, dateAccept, dateDelivery, docs, funds, ...rest} =
    compositionGeneric;
  const composition: ICompositionEntity = {
    ...rest,
    date: date.toISOString(),
    dateAccept: dateAccept ? dateAccept.toISOString() : undefined,
    dateDelivery: dateDelivery ? dateDelivery.toISOString() : undefined,
    jsonFunds: JSON.stringify(funds),
    jsonDocs: docs ? JSON.stringify(docs) : undefined,
  };

  return composition;
};

export const compositionEntityUtility = entityUtilityFactory<
  ICompositionEntity,
  ICompositionDB,
  IComposition,
  EntityShape
>({
  schema: compositionSchema,
  deserialize: compositionParse,
  serialize: compositionstringify,
});
