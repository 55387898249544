import React from "react";
import RequestOTPModal from "../ESign/RequestOTPContainer";
import PrivacyModal from "../User/PrivacyModal";
import Footer from "./Footer";
import Header from "./Header";

export const Layout: React.FC = ({children}) => (
  <>
    <Header />
    <main id="main" className={"main auto-margin-4"}>
      {children}
    </main>
    <Footer />
    <PrivacyModal />
    <RequestOTPModal />
  </>
);
