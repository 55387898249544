import {createAsyncThunk} from "@reduxjs/toolkit";
import {actions as capsActions} from "../../../Caps/actions";
import {createAsyncThunkAndReset} from "../../../helpers/createAsyncThunkAndReset";
import {actions as usersActions} from "../../../Users/actions";
import {IChooseFundsFormData} from "../../New/ChooseFundsForm";
import service from "../../service";
import {
  compositionEntityUtility,
  ICompositionParams,
  SelectedFund,
} from "../../types";
import {actions as entityActions} from "../entities";

interface CreateParams {
  values: IChooseFundsFormData;
  capId: string;
  userId: string;
}

export const create = createAsyncThunkAndReset(
  "compositions/create",
  async ({values, capId, userId}: CreateParams, thunkAPI) => {
    const selectedFunds = [] as SelectedFund[];
    const {funds, ...data} = values;

    Object.keys(funds).forEach((key) => {
      if (parseInt(funds[key].percent) > 0) {
        selectedFunds.push({id: key, percent: funds[key].percent});
      }
    });

    const createdComposition = await service.create({
      ...data,
      jsonSelectedFunds: JSON.stringify(selectedFunds),
      capId,
      userId,
    });
    const {
      result,
      entities: {users, compositions, caps},
    } = compositionEntityUtility.normalizeEntity(createdComposition);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(compositions ?? {}));

    return {compositionId: result};
  }
);

export const get = createAsyncThunk(
  "compositions/get",
  async (id: string, thunkAPI) => {
    const composition = await service.read(id);
    const {
      result,
      entities: {users, compositions, caps},
    } = compositionEntityUtility.normalizeEntity(composition);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(compositions ?? {}));

    return {compositionsId: result};
  }
);

export const list = createAsyncThunk(
  "compositions/list",
  async (params: ICompositionParams, thunkAPI) => {
    const {numCompositions, compositionList} = await service.list(params);
    const {
      result,
      entities: {users, compositions, caps},
    } = compositionEntityUtility.normalizeEntity(compositionList);

    thunkAPI.dispatch(usersActions.mergeItems(users));
    thunkAPI.dispatch(capsActions.mergeItems(caps));
    thunkAPI.dispatch(entityActions.received(compositions ?? {}));

    return {compositionsIds: result, numCompositions, params};
  }
);

export const sendNotification = createAsyncThunk(
  "compositions/sendNotification",
  async (id: string) => {
    return await service.sendNotification(id);
  }
);
