import {
  conoscenzaEsperienzaCriteriaType,
  ICap,
  obiettiviInvestimentoType,
  orizzonteTemporaleType,
  propensioneRischioType,
  situazioneFinanziariaType,
} from "../../../types";
import {SingleKeys} from "./IddForm";

export const conoscenzaEsperienzaCriteria: conoscenzaEsperienzaCriteriaType[] =
  ["Bassa", "Medio-bassa", "Medio-alta", "Alta"];

export const situazioneFinanziariaCriteria: situazioneFinanziariaType[] = [
  "Critica",
  "Debole",
  "Moderata",
  "Solida",
];

export const obiettiviInvestimentoCriteria: obiettiviInvestimentoType[] = [
  "Conservazione",
  "Reddito",
  "Crescita",
  "Crescita rilevante",
];

export const propensioneRischioCriteria: propensioneRischioType[] = [
  "Cauto",
  "Prudente",
  "Bilanciato",
  "Dinamico",
];

export const orizzonteTemporaleCriteria: orizzonteTemporaleType[] = [
  "Molto breve",
  "Breve",
  "Medio",
  "Lungo",
];

interface IDomandeIddQuestion {
  answers: Array<{text: string; value: string}>;
  label: string;
  multiple?: boolean;
  name: SingleKeys;
  getInitialValue?: (cap: ICap) => string | Array<null | true>;
}

interface IDomandeIddSection {
  questions: IDomandeIddQuestion[];
  title: string;
}

export interface IDomandeIdd {
  sections: IDomandeIddSection[];
}

const iddQuestions: IDomandeIdd = {
  sections: [
    {
      questions: [
        {
          answers: [
            {text: "Sì", value: "1"},
            {text: "No", value: "2"},
            {text: "Nessuna risposta", value: "3"},
          ],
          label:
            "Lei ha mai svolto formazione specifica in materia finanziaria oppure possiede una specifica esperienza professionale in ambito finanziario?",
          name: "question_c1",
        },
        {
          answers: [
            {
              text: "Non ho nessuna esperienza",
              value: "1",
            },
            {
              text: "Da 0 a 3 anni",
              value: "2",
            },
            {
              text: "Da 3 a 5 anni",
              value: "3",
            },
            {
              text: "Più di 5 anni",
              value: "4",
            },
            {text: "Nessuna risposta", value: "5"},
          ],
          label: "Da quanto tempo Lei investe in strumenti finanziari?",
          name: "question_c2",
        },
        {
          answers: [
            {
              text: "BASE: non ho nessuna conoscenza oppure una conoscenza di base degli strumenti finanziari. Esempi: Titoli di stato a breve termine (BOT), certificati di deposito, fondi di liquidità, obbligazioni a tasso variabile a breve termine, ecc.",
              value: "1",
            },
            {
              text: "MEDIA: ho una discreta conoscenza dei principali strumenti finanziari. So ad esempio che il capitale investito in titoli azionari subisce generalmente maggiori variazioni rispetto all'investimento in titoli obbligazionari e che l'investimento diversificato consente di attenuare i potenziali rischi connessi. Esempi: Titoli di stato a medio e lungo termine (CCT, BTP, obbligazioni non strutturate, fondi obbligazionari, fondi bilanciati, fondi azionari, azioni, ecc.)",
              value: "2",
            },
            {
              text: "BUONA: ho un'ampia conoscenza di strumenti finanziari, anche strumenti complessi. Conosco i principali elementi di rischio per es. azionario, valutario, emittente, legato alle diverse tipologie di strumenti finanziari. Esempi: obbligazioni singole strutturate, ecc.",
              value: "3",
            },
            {
              text: "AVANZATA: ho un'approfondita conoscenza di strumenti finanziari. Sono in grado di valutare pienamente i rischi di investimento in strumenti complessi ed anche in strumenti derivati e mi aggiorno sull'evoluzione dei mercati finanziari. Esempi: warrant, covered warrant, certificates, hedge funds, ecc.",
              value: "4",
            },
            {text: "Nessuna risposta", value: "5"},
          ],
          label:
            "Qual è il suo stato di conoscenze sugli strumenti finanziari esistenti?",
          name: "question_c3",
        },
      ],
      title: "Esperienza e conoscenze",
    },
    {
      questions: [
        {
          answers: [
            {
              text: "Lavoro dipendente a tempo determinato o indeterminato",
              value: "1",
            },
            {
              text: "Lavoro autonomo, libero professionista, imprenditore",
              value: "2",
            },
            {
              text: "Reddito irregolare, disoccupato, lavoro precario",
              value: "3",
            },
            {
              text: "Reddito da pensione",
              value: "4",
            },
            {
              text: "Reddito diverso (reddito da immobili, reddito da capitale, ecc)",
              value: "5",
            },
            {text: "Nessuna risposta", value: "6"},
          ],
          label: "Qual è la sua principale fonte di reddito?",
          name: "question_d1",
        },
        {
          answers: [
            {text: "Fino a 40.000 €", value: "1"},
            {text: "Da 40.000 € a 60.000 €", value: "2"},
            {text: "Più di 60.000 €", value: "3"},
            {text: "Nessuna risposta", value: "4"},
          ],
          label:
            "Si ricorda a quanto ammonta, grosso modo, il suo reddito annuo?",
          name: "question_d2",
        },
        {
          answers: [
            {text: "Fino a 1.000 €", value: "1"},
            {text: "Da 1.000 a 2.000 €", value: "2"},
            {text: "Più di 2.000 €", value: "3"},
            {text: "Nessuna risposta", value: "4"},
          ],
          label:
            "Quali sono, indicativamente, i suoi impegni finanziari nel corso di un mese (affitto, rate mutui, rate di credito al consumo, alimenti...)",
          name: "question_d3",
        },
        {
          answers: [
            {text: "Fino a 50.000€", value: "1"},
            {text: "Da 50.000€ - 250.000 €", value: "2"},
            {text: "Da 250.000 € - 500.000 €", value: "3"},
            {text: "Oltre i 500.000 €", value: "4"},
            {text: "Nessuna risposta", value: "5"},
          ],
          label:
            "A quanto ammonta, più o meno, il suo patrimonio complessivo? (finanziario e immobiliare)",
          name: "question_d4",
        },
      ],
      title: "Situazione finanziaria",
    },
    {
      questions: [
        {
          answers: [
            {
              text: "Impiego temporaneo di liquidità, investendo in strumenti prontamente liquidabili e con redditività conseguentemente modesta (propensione al rischio: BASSO)",
              value: "1",
            },
            {
              text: "Gestione del risparmio e suo moderato accrescimento, accettando moderate oscillazioni o riduzioni di valore dell'investimento nel tempo (propensione al rischio: MEDIO-BASSO)",
              value: "2",
            },
            {
              text: "Accrescimento del capitale, accettando significative oscillazioni o riduzioni di valore dell'investimento (Propensione al rischio: MEDIO-ALTO)",
              value: "3",
            },
            {
              text: "Accrescimento significativo del capitale, essendo disposto a sopportare anche forti oscillazioni o riduzioni di valore dell'investimento (Propensione al rischio: ALTO)",
              value: "4",
            },
            {
              text: "Nessuna risposta",
              value: "5",
            },
          ],
          label:
            "Quali sono i suoi obiettivi di investimento e la sua propensione al rischio?",
          name: "question_e1",
        },
        {
          answers: [
            {text: "Fino a 3 anni", value: "1"},
            {text: "Da 3 anni a 5 anni", value: "2"},
            {text: "Da 5 anni a 15 anni", value: "3"},
            {text: "Più di 15 anni", value: "4"},
            {text: "Nessuna risposta", value: "5"},
          ],
          label:
            "Con quale orizzonte temporale intende perseguire i suoi obiettivi di investimento?",
          name: "question_e2",
        },
        {
          answers: [
            {
              text: "Non risulta determinante per il mantenimento del tenore di vita",
              value: "1",
            },
            {
              text: "Contribuisce parzialmente al mantenimento del tenore di vita",
              value: "2",
            },
            {
              text: "E' indispensabile al mantenimento del tenore di vita e non esclude la possibilità di attingere al patrimonio previsto",
              value: "3",
            },
            {
              text: "Nessuna risposta",
              value: "4",
            },
          ],
          label:
            "Nel suo caso, il rendimento derivante dagli investimenti finanziari influisce sul mantenimento del tenore di vita?",
          name: "question_e3",
        },
        {
          answers: [
            {text: "Non incide", value: "1"},
            {text: "Incide in maniera marginale", value: "2"},
            {text: "Incide in maniera significativa", value: "3"},
            {text: "Nessuna risposta", value: "4"},
          ],
          label:
            "A suo avviso, un'eventuale perdita (intorno al 15% sul patrimonio finanziario nel corso di un anno) può incidere sul mantenimento di impegni finanziari regolari(per esempio, affitti, rate di mutui, rate di crediti al consumo, sostentamenti, ecc.)?",
          name: "question_e4",
        },
      ],
      title: "Obiettivo di investimento e tolleranza al rischio",
    },
  ],
};

export default iddQuestions;
