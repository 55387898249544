import {apiService} from "../App/service";
import {IIddProfile} from "./NewPage/IBIPsSection/IBIPsDrawer/computeIdd";
import {IDomandeIdd} from "./NewPage/IBIPsSection/IBIPsDrawer/iddQuestions";
import {IIddFormData} from "./NewPage/IBIPsSection/IBIPsDrawer/IddForm";
import {
  ICapDB,
  ICapEntity,
  ICapParams,
  ICity,
  ICompanyParams,
  ICopyData,
  IInvestmentCompany,
  IInvestmentFund,
  IInvestmentOption,
} from "./types";

const baseApiUrl = `${process.env.REACT_APP_API_BASE_URL}`;
const apiUrl = `${process.env.REACT_APP_API_BASE_URL}caps`;

const capService = {
  create(cap: Partial<ICapEntity>) {
    return apiService
      .post(apiUrl, cap)
      .then((responseJson) => responseJson.cap as ICapDB);
  },

  read(id: string) {
    return apiService
      .get(`${apiUrl}/${id}`)
      .then((responseJson) => responseJson.cap as ICapDB);
  },

  list(params?: ICapParams) {
    return apiService.get(apiUrl, params).then((responseJson) => ({
      capsList: responseJson.caps as ICapDB[],
      numCaps: responseJson.numCaps as number,
    }));
  },

  update(id: string, cap: Partial<ICapEntity>) {
    return apiService
      .put(`${apiUrl}/${id}`, cap)
      .then((responseJson) => responseJson.cap as ICapDB);
  },

  delete(id: string) {
    return apiService.delete(`${apiUrl}/${id}`);
  },

  close(id: string, emails: string[], password: string) {
    return apiService
      .post(`${apiUrl}/${id}/close`, {
        emails: JSON.stringify(emails),
        password,
      })
      .then((responseJson) => responseJson.cap as ICapDB);
  },

  iddSend(id: string) {
    return apiService.get(`${apiUrl}/${id}/sendidd`);
  },

  setIdd(
    id: string,
    idd: IIddProfile,
    values: IIddFormData,
    questions: IDomandeIdd
  ) {
    return apiService
      .post(`${apiUrl}/${id}/set-idd`, {
        idd: JSON.stringify(idd),
        questions: JSON.stringify(questions),
        values: JSON.stringify(values),
      })
      .then((responseJson) => responseJson.cap as ICapDB);
  },

  closeOverdrafts(id: string) {
    return apiService
      .post(`${apiUrl}/${id}/close-overdraft`, {})
      .then((responseJson) => responseJson.cap as ICapDB);
  },

  requestValidation(id: string, email?: string) {
    return apiService
      .post(`${apiUrl}/${id}/send-validation`, {
        email,
      })
      .then((responseJson) => responseJson.cap as ICapDB);
  },

  identificationNotification(id: string) {
    return apiService.get(`${apiUrl}/${id}/identification-notification`);
  },

  closeValidation(
    id: string,
    responseValidation: number,
    fiscalCode?: string,
    password?: string
  ) {
    return apiService
      .put(`${apiUrl}/${id}`, {
        fiscalCode,
        password,
        responseValidation,
      })
      .then((responseJson) => responseJson.cap as ICapDB);
  },

  loadCompanies(params?: Partial<ICompanyParams>) {
    return apiService
      .get(`${apiUrl}/companies`, params)
      .then((responseJson) => responseJson.companies as IInvestmentCompany[]);
  },

  loadOptions() {
    return apiService
      .get(`${apiUrl}/options`)
      .then((responseJson) => responseJson.options as IInvestmentOption[]);
  },

  loadFunds(onlyAssociatedFunds: boolean) {
    return apiService
      .get(
        `${apiUrl}/funds`,
        onlyAssociatedFunds
          ? {"only-associated-funds": onlyAssociatedFunds}
          : undefined
      )
      .then((responseJson) => responseJson.funds as IInvestmentFund[]);
  },

  loadCopy(id: string) {
    return apiService
      .get(`${apiUrl}/${id}/copy-data`)
      .then(
        ({den, hasIBIPs, overdrafts}) =>
          ({den, hasIBIPs, overdrafts} as ICopyData)
      );
  },

  loadCities(options?: {exist?: boolean; name?: string; limit?: number}) {
    return apiService
      .get(`${baseApiUrl}cities`, options)
      .then((responseJson) => responseJson.cities as ICity[]);
  },
};

export default capService;
