import React from "react";
import {Modal, ModalBody} from "reactstrap";
import {IAppThunkActionStates} from "../types/thunk";
import {IUser} from "../Users/types";
import InsertPhoneForm, {IInsertPhoneFormData} from "./InsertPhoneForm";
import RequestOTPForm from "./RequestOTPForm";
import {IESign, ISignFEADocRequestData, PDFTypes} from "./types";

interface IProps {
  additionalParams?: {[key: string]: string};
  addPersonalPhone: (values: IInsertPhoneFormData) => Promise<void>;
  addContractorPhone: (
    contractorId: string,
    values: IInsertPhoneFormData
  ) => Promise<void>;
  cancelESign: () => void;
  closeEditNumberForm: () => void;
  createFEATransaction: (contractorId?: string) => Promise<void>;
  createFEATransactionStates: IAppThunkActionStates;
  isModalOpen: boolean;
  isProfileFail?: boolean;
  isProfilePending?: boolean;
  isUpdatingPhone?: boolean;
  loggedUser: IUser | null;
  openEditNumberForm: () => void;
  openedTransaction: IESign | undefined;
  pdfType: PDFTypes | undefined;
  profileError?: string;
  referenceId: string | undefined;
  signFEADoc: (data: ISignFEADocRequestData) => Promise<void>;
  signFEADocStates: IAppThunkActionStates;
  signingUser: IUser | undefined;
}

const RequestOTPModal: React.FC<IProps> = ({
  addContractorPhone,
  additionalParams,
  addPersonalPhone,
  cancelESign,
  closeEditNumberForm,
  createFEATransaction,
  createFEATransactionStates,
  isModalOpen,
  isProfileFail,
  isProfilePending,
  isUpdatingPhone,
  loggedUser,
  openEditNumberForm,
  openedTransaction,
  pdfType,
  profileError,
  referenceId,
  signFEADoc,
  signFEADocStates,
  signingUser,
}) => {
  if (!loggedUser) {
    return null;
  }

  return (
    <Modal isOpen={isModalOpen} toggle={cancelESign} backdrop="static" centered>
      <ModalBody>
        {(signingUser || loggedUser).personalPhone && !isUpdatingPhone ? (
          <RequestOTPForm
            additionalParams={additionalParams}
            cancelESign={cancelESign}
            forContractor={!!signingUser && signingUser.id !== loggedUser.id}
            loggedUser={loggedUser}
            openedTransaction={openedTransaction!}
            pdfType={pdfType!}
            isModalOpen={isModalOpen}
            referenceId={referenceId!}
            requestOTP={createFEATransaction}
            requestOTPStates={createFEATransactionStates}
            signFEADoc={signFEADoc}
            signFEADocStates={signFEADocStates}
            signingUser={signingUser || loggedUser}
            openEditNumberForm={openEditNumberForm}
          />
        ) : (
          <InsertPhoneForm
            addPersonalPhone={addPersonalPhone}
            addContractorPhone={addContractorPhone}
            forContractor={!!signingUser && signingUser.id !== loggedUser.id}
            cancelESign={cancelESign}
            isProfileFail={isProfileFail}
            isProfilePending={isProfilePending}
            initialValues={{
              personalPhone:
                isUpdatingPhone && (signingUser || loggedUser).personalPhone
                  ? (signingUser || loggedUser).personalPhone
                  : (signingUser || loggedUser).businessPhone,
            }}
            profileError={profileError}
            signingUser={signingUser || loggedUser}
            closeEditNumberForm={closeEditNumberForm}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default RequestOTPModal;
