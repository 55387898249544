import {Dispatch} from "@reduxjs/toolkit";
import {batch} from "react-redux";
import {actions as beneProposalsActions} from "../BeneProposals/slice";
import {actions as compositionActions} from "../Compositions/slice";
import {beneProposalEntityUtility} from "../BeneProposals/types";
import {compositionEntityUtility} from "../Compositions/types";
import {actions as elementaryClassesActions} from "../ElementaryClasses/slice";
import {actions as capsActions} from "../Caps/actions";
import {normalizeCap} from "../Caps/types";
import {elementaryClassEntityUtility} from "../ElementaryClasses/types";
import {actions as proposalsActions} from "../Proposals/actions";
import {normalizeProposal} from "../Proposals/types";
import IApiError from "../types/IApiError";
import {actions as userActions} from "../User/actions";
import {actions as usersActions} from "../Users/actions";
import service from "./service";
import {
  IESignsEntities,
  ISignFEADocRequestData,
  normalizeESign,
  PDFTypes,
} from "./types";

export enum ActionTypes {
  MERGE_ITEMS = "[ESign] Merge items",

  CLOSE_MODAL = "[ESign] Close modal",
  OPEN_MODAL = "[ESign] Open modal",

  CLOSE_EDIT_NUMBER = "[ESign] Close edit number form",
  OPEN_EDIT_NUMBER = "[ESign] Open edit number form",

  CREATE_FEA_TRANSACTION_REQUEST = "[ESign] Create FEA transaction request",
  CREATE_FEA_TRANSACTION_SUCCESS = "[ESign] Create FEA transaction success",
  CREATE_FEA_TRANSACTION_FAIL = "[ESign] Create FEA transaction fail",
  CREATE_FEA_TRANSACTION_RESET = "[ESign] Create FEA transaction reset",

  SIGN_FEA_DOC_REQUEST = "[ESign] Sign FEA doc request",
  SIGN_FEA_DOC_SUCCESS = "[ESign] Sign FEA doc success",
  SIGN_FEA_DOC_FAIL = "[ESign] Sign FEA doc fail",
  SIGN_FEA_DOC_RESET = "[ESign] Sign FEA doc reset",
}

interface IMergeItemsAction {
  type: typeof ActionTypes.MERGE_ITEMS;
  payload: {eSigns: IESignsEntities};
}

interface IOpenModalAction {
  payload: {
    pdfType: PDFTypes;
    referenceId: string;
    additionalParams?: {[key: string]: string};
  };
  type: typeof ActionTypes.OPEN_MODAL;
}
interface ICloseModalAction {
  type: typeof ActionTypes.CLOSE_MODAL;
}

interface IOpenEditNumber {
  type: typeof ActionTypes.OPEN_EDIT_NUMBER;
}
interface ICloseEditNumber {
  type: typeof ActionTypes.CLOSE_EDIT_NUMBER;
}

interface ICreateFEATransactionRequestAction {
  type: typeof ActionTypes.CREATE_FEA_TRANSACTION_REQUEST;
}
interface ICreateFEATransactionSuccessAction {
  type: typeof ActionTypes.CREATE_FEA_TRANSACTION_SUCCESS;
  payload: {eSignId: string};
}
interface ICreateFEATransactionFailAction {
  type: typeof ActionTypes.CREATE_FEA_TRANSACTION_FAIL;
  payload: IApiError;
}
interface ICreateFEATransactionResetAction {
  type: typeof ActionTypes.CREATE_FEA_TRANSACTION_RESET;
}

interface ISignFEADocRequestAction {
  type: typeof ActionTypes.SIGN_FEA_DOC_REQUEST;
}
interface ISignFEADocSuccessAction {
  type: typeof ActionTypes.SIGN_FEA_DOC_SUCCESS;
  payload: {eSignId: string};
}
interface ISignFEADocFailAction {
  type: typeof ActionTypes.SIGN_FEA_DOC_FAIL;
  payload: IApiError;
}
interface ISignFEADocResetAction {
  type: typeof ActionTypes.SIGN_FEA_DOC_RESET;
}

export const actions = {
  mergeItems: (eSigns: IESignsEntities): IMergeItemsAction => ({
    payload: {eSigns},
    type: ActionTypes.MERGE_ITEMS,
  }),

  closeModal: (): ICloseModalAction => ({
    type: ActionTypes.CLOSE_MODAL,
  }),
  openModal: (
    pdfType: PDFTypes,
    referenceId: string,
    additionalParams?: {[key: string]: string}
  ): IOpenModalAction => ({
    payload: {pdfType, referenceId, additionalParams},
    type: ActionTypes.OPEN_MODAL,
  }),

  openEditNumberForm: (): IOpenEditNumber => ({
    type: ActionTypes.OPEN_EDIT_NUMBER,
  }),
  closeEditNumberForm: (): ICloseEditNumber => ({
    type: ActionTypes.CLOSE_EDIT_NUMBER,
  }),

  cancelESign: () => (dispatch: Dispatch<Actions | usersActions>) => {
    batch(() => {
      dispatch(actions.closeModal());
      dispatch(actions.createFEATransactionReset());
      dispatch(actions.signFEADocReset());
    });
  },

  // TODO: potrebbe essere utile avere un thunk che avvia il processo di firma e torna una Promise che si risolve
  //  quando viene completato la FEATransaction
  waitForEsign:
    (
      pdfType: PDFTypes,
      referenceId: string,
      additionalParams?: {[key: string]: string}
    ) =>
    async (dispatch: Dispatch) => {
      dispatch(actions.openModal(pdfType, referenceId, additionalParams));
    },

  createFEATransaction:
    (contractorId?: string) => (dispatch: Dispatch<Actions | usersActions>) => {
      dispatch(actions.createFEATransactionRequest());
      return service
        .createFEATransaction(contractorId)
        .then((eSign) => {
          const {
            result,
            entities: {eSigns, users},
          } = normalizeESign(eSign);
          batch(() => {
            dispatch(actions.mergeItems(eSigns));
            dispatch(usersActions.mergeItems(users));

            dispatch(actions.createFEATransactionSuccess(result));
          });
        })
        .catch((error) => {
          dispatch(actions.createFEATransactionFail(error));
        });
    },
  createFEATransactionFail: (
    error: IApiError
  ): ICreateFEATransactionFailAction => ({
    payload: error,
    type: ActionTypes.CREATE_FEA_TRANSACTION_FAIL,
  }),
  createFEATransactionRequest: (): ICreateFEATransactionRequestAction => ({
    type: ActionTypes.CREATE_FEA_TRANSACTION_REQUEST,
  }),
  createFEATransactionReset: (): ICreateFEATransactionResetAction => {
    return {
      type: ActionTypes.CREATE_FEA_TRANSACTION_RESET,
    };
  },
  createFEATransactionSuccess: (
    eSignId: string
  ): ICreateFEATransactionSuccessAction => {
    return {
      payload: {eSignId},
      type: ActionTypes.CREATE_FEA_TRANSACTION_SUCCESS,
    };
  },

  signFEADoc: (data: ISignFEADocRequestData) => (dispatch: Dispatch) => {
    dispatch(actions.signFEADocRequest());
    return service
      .signFEADoc(data)
      .then(
        ({
          eSign,
          file,
          beneProposal,
          cap,
          composition,
          elementaryClass,
          proposal,
          user,
        }) => {
          const {
            result,
            entities: {eSigns, users},
          } = normalizeESign(eSign);
          batch(() => {
            if (
              [PDFTypes.Privacy, PDFTypes.PrivacySubscription].includes(
                data.pdfType
              )
            ) {
              dispatch(userActions.updateUserLocalStorage({user: user as any}));
            }
            if (file) {
              // Se la risposta contiene un campo "file" questo è relativo al doc temporaneo appena firmato
              //  per le proposte bene
              dispatch(
                beneProposalsActions.congruenceFileCreated({
                  path: file,
                  eSignId: eSign.id,
                })
              );
            }
            if (beneProposal) {
              const {
                entities: {beneProposals},
              } = beneProposalEntityUtility.normalizeEntity(beneProposal);
              dispatch(beneProposalsActions.received(beneProposals));
            }
            if (cap) {
              const {
                entities: {caps},
              } = normalizeCap(cap);
              dispatch(capsActions.mergeItems(caps));
            }
            if (composition) {
              const {
                entities: {compositions},
              } = compositionEntityUtility.normalizeEntity(composition);
              dispatch(compositionActions.received(compositions));
            }
            if (elementaryClass) {
              const {
                entities: {elementaryClasses},
              } = elementaryClassEntityUtility.normalizeEntity(elementaryClass);
              dispatch(elementaryClassesActions.received(elementaryClasses));
            }
            if (proposal) {
              const {
                entities: {proposals},
              } = normalizeProposal(proposal);
              dispatch(proposalsActions.mergeItems(proposals));
            }
            dispatch(usersActions.mergeItems(users));
            dispatch(actions.mergeItems(eSigns));
            dispatch(actions.signFEADocSuccess(result));
          });
        }
      )
      .catch((error) => {
        dispatch(actions.signFEADocFail(error));
      });
  },
  signFEADocFail: (error: IApiError): ISignFEADocFailAction => ({
    payload: error,
    type: ActionTypes.SIGN_FEA_DOC_FAIL,
  }),
  signFEADocRequest: (): ISignFEADocRequestAction => ({
    type: ActionTypes.SIGN_FEA_DOC_REQUEST,
  }),
  signFEADocReset: (): ISignFEADocResetAction => {
    return {
      type: ActionTypes.SIGN_FEA_DOC_RESET,
    };
  },
  signFEADocSuccess: (eSignId: string): ISignFEADocSuccessAction => {
    return {
      payload: {eSignId},
      type: ActionTypes.SIGN_FEA_DOC_SUCCESS,
    };
  },
};

export type Actions =
  | IMergeItemsAction
  | IOpenModalAction
  | ICloseModalAction
  | IOpenEditNumber
  | ICloseEditNumber
  | ICreateFEATransactionRequestAction
  | ICreateFEATransactionSuccessAction
  | ICreateFEATransactionFailAction
  | ICreateFEATransactionResetAction
  | ISignFEADocRequestAction
  | ISignFEADocSuccessAction
  | ISignFEADocFailAction
  | ISignFEADocResetAction;
