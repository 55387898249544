import {apiService} from "../App/service";
import {IBeneProposalDB} from "../BeneProposals/types";
import {ICapDB} from "../Caps/types";
import {ICompositionDB} from "../Compositions/types";
import {IElementaryClassDB} from "../ElementaryClasses/types";
import {IProposalDB} from "../Proposals/types";
import {IUserDB} from "../Users/types";
import {IESignDB, ISignFEADocRequestData} from "./types";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}esigns/`;

const service = {
  createFEATransaction(contractorId?: string) {
    return apiService
      .post(`${apiUrl}create-featransaction`, {contractorId})
      .then((responseJson) => responseJson.esign as IESignDB);
  },

  signFEADoc(data: ISignFEADocRequestData) {
    return apiService.post(`${apiUrl}sign-feadoc`, data).then(
      (responseJson) =>
        ({
          eSign: responseJson.esign,
          ...(responseJson.file && {
            file: responseJson.file,
          }),
          ...(responseJson.bene && {beneProposal: responseJson.bene}),
          ...(responseJson.cap && {cap: responseJson.cap}),
          ...(responseJson.elementaryClass && {
            elementaryClass: responseJson.elementaryClass,
          }),
          ...(responseJson.proposal && {proposal: responseJson.proposal}),
          ...(responseJson.composition && {
            composition: responseJson.composition,
          }),
          ...(responseJson.user && {user: responseJson.user}),
        } as {
          eSign: IESignDB;
          file?: string;
          beneProposal?: IBeneProposalDB;
          cap?: ICapDB;
          composition?: ICompositionDB;
          elementaryClass?: IElementaryClassDB;
          proposal?: IProposalDB;
          user?: IUserDB;
        })
    );
  },
};

export default service;
