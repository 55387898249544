import {ArrayElement} from "../../../../types/ArrayElement";
import {IIddFormData} from "./IddForm";

export const levels = [
  {
    level: "Livello I",
    description:
      "L’importanza della protezione del capitale e la sicurezza degli investimenti sono prioritarie. Non si ha la possibilità o non si è disposti ad accettare oscillazioni di valore, sia pure contenute, degli investimenti.min:",
    min: 1,
    max: 2,
  },
  {
    level: "Livello II",
    description:
      "Si attribuisce grande importanza alla protezione del capitale ed alla sicurezza degli investimenti. Il rendimento atteso è in linea con la conservazione nel tempo del potere d’acquisto del capitale investito, contenendo – per quanto possibile e tenuto conto dell’obiettivo prefissato – le oscillazioni di valore dell’investimento.",
    min: 3,
    max: 3,
  },
  {
    level: "Livello III",
    description:
      "Pur attribuendo importanza alla protezione del capitale ed alla sicurezza degli investimenti, si mira ad ottenere nel medio periodo rendimenti superiori a quelli che si otterrebbero dagli strumenti del mercato monetario, accettando oscillazioni di valore dell’investimento coerenti con l’obiettivo dichiarato.",
    min: 4,
    max: 5,
  },
  {
    level: "Livello IV",
    description:
      "L’importanza della protezione del capitale investito è minore rispetto all’esigenza di rendimento. Il profilo mira ad ottenere nel medio periodo rendimenti per i quali si è disposti a tollerare oscillazioni di media entità del valore dell’investimento.",
    min: 6,
    max: 7,
  },
  {
    level: "Livello V",
    description:
      "L’importanza della protezione del capitale e della sicurezza degli investimenti è secondaria rispetto al rendimento atteso. Il Cliente mira, in particolare, ad ottenere rendimenti medio-alti nel medio-lungo termine, con elevata tolleranza rispetto a considerevoli/forti oscillazioni di valore degli investimenti.",
    min: 8,
    max: 10,
  },
  {
    level: "Livello VI",
    description:
      "La ricerca di elevati rendimenti è prioritaria rispetto alla protezione del capitale. Il Cliente che abbia questo profilo di rischio-rendimento atteso mira ad ottenere rendimenti alti nel lungo termine, sopportando forti oscillazioni di valore degli investimenti.",
    min: 11,
    max: 12,
  },
  {
    level: "Livello VII",
    description:
      "Si rileva l’acquisizione di informazioni che consentono, anche in base all’esperienza e conoscenza in materia di investimenti, ed una dichiarata propensione al rischio elevata, di proporre prodotti ad alta propensione al rischio, atti alla ricerca di rendimenti elevati, nel lungo termine, sopportando anche forti oscillazioni del valore dell’investimento e consapevole del rischio di mancata protezione del capitale.",
    min: 13,
    max: 15,
  },
];

export const indications = [
  {
    profile: "Profilo I",
    monetaryInstruments: "100,00%",
    safetyInstruments: "0,00%",
    accretiveInstruments: "0,00%",
    yearVolatility: "0,1% - 1,4%",
  },
  {
    profile: "Profilo II",
    monetaryInstruments: "60,00%",
    safetyInstruments: "32,55%",
    accretiveInstruments: "7,50%",
    yearVolatility: "0,9% – 2,3%",
  },
  {
    profile: "Profilo III",
    monetaryInstruments: "42,50%",
    safetyInstruments: "40,00%",
    accretiveInstruments: "17,50%",
    yearVolatility: "1,6% - 4,1%",
  },
  {
    profile: "Profilo IV",
    monetaryInstruments: "30,00%",
    safetyInstruments: "40,00%",
    accretiveInstruments: "30,00%",
    yearVolatility: "2,7% – 6,7%",
  },
  {
    profile: "Profilo V",
    monetaryInstruments: "10,00%",
    safetyInstruments: "50,00%",
    accretiveInstruments: "40,00%",
    yearVolatility: "3,6% - 8,9%",
  },
  {
    profile: "Profilo VI",
    monetaryInstruments: "10,00%",
    safetyInstruments: "30,00%",
    accretiveInstruments: "60,00%",
    yearVolatility: "5,3% - 9,4%",
  },
  {
    profile: "Profilo VII",
    monetaryInstruments: "0,00%",
    safetyInstruments: "10,00%",
    accretiveInstruments: "90,00%",
    yearVolatility: "6,4%-13,5%",
  },
];

export interface IIddProfile
  extends ArrayElement<typeof levels>,
    ArrayElement<typeof indications> {
  score: number;
  levels: typeof levels;
  indications: typeof indications;
}

export const computeIdd = (data: IIddFormData): IIddProfile => {
  const personalData = computePersonalData(data);
  const experience = computeExperience(data);
  const financialSituation = computeFinancialSituation(data);
  const investmentObjective = computeInvestmentObjective(data);
  const score =
    personalData + experience + financialSituation + investmentObjective;

  const levelIndex = levels.findIndex(
    (level) => score >= level.min && score <= level.max
  );

  if (levelIndex === -1) {
    throw new Error(`nessun livello corrispondente a ${score}`);
  }

  return {
    ...levels[levelIndex],
    ...indications[levelIndex],
    score,
    levels,
    indications,
  };
};

const computePersonalData = (data: IIddFormData) => {
  return 1;
};

const computeExperience = (data: IIddFormData) => {
  const areaWeights = [
    [4, 1, 0],
    [1, 2, 3, 4, 0],
    [1, 2, 3, 4, 0],
  ];
  const areaAnswers = [data.question_c1, data.question_c2, data.question_c3];
  const questionWeight = [0.33, 0.33, 0.34];
  const areaScore = 4;

  return computeSectionScore(
    areaWeights,
    areaAnswers,
    questionWeight,
    areaScore
  );
};

const computeFinancialSituation = (data: IIddFormData) => {
  const areaWeights = [
    [4, 4, 1, 2, 1, 0],
    [1, 3, 5, 0],
    [5, 3, 1, 0],
    [1, 2, 3, 5, 0],
  ];
  const areaAnswers = [
    data.question_d1,
    data.question_d2,
    data.question_d3,
    data.question_d4,
  ];
  const questionWeight = [0.25, 0.25, 0.25, 0.25];
  const areaScore = 5;

  return computeSectionScore(
    areaWeights,
    areaAnswers,
    questionWeight,
    areaScore
  );
};

const computeInvestmentObjective = (data: IIddFormData) => {
  const areaWeights = [
    [1, 2, 3, 5, 0],
    [1, 2, 4, 5, 0],
    [4, 2, 1, 0],
    [5, 3, 1, 0],
  ];
  const areaAnswers = [
    data.question_e1,
    data.question_e2,
    data.question_e3,
    data.question_e4,
  ];
  const questionWeight = [0.25, 0.25, 0.25, 0.25];
  const areaScore = 5;

  return computeSectionScore(
    areaWeights,
    areaAnswers,
    questionWeight,
    areaScore
  );
};

const computeSectionScore = (
  areaWeights: number[][],
  areaAnswers: string[],
  questionWeight: number[],
  areaScore: number
) => {
  const answersScore = areaAnswers.reduce((prev, curr, index) => {
    const answerIndex = parseInt(curr, 10) - 1;
    return prev + areaWeights[index][answerIndex] * questionWeight[index];
  }, 0);
  const weightSum = questionWeight.reduce((prev, curr) => prev + curr, 0);
  const score = answersScore / weightSum;

  const maxArea = areaWeights.reduce((prev, curr, index) => {
    return prev + Math.max(...curr) * questionWeight[index];
  }, 0);

  return Math.round((score / maxArea) * areaScore);
};
