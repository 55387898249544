import * as Sentry from "@sentry/browser";
import {Action, Dispatch, Middleware, MiddlewareAPI} from "redux";
import {getLSUser} from "../User/getLSUser";
import {IRootState} from "./reducers";

function compressState(state: IRootState): IRootState {
  const stateClone = JSON.parse(JSON.stringify(state)) as IRootState;

  Object.keys(stateClone.caps.entities).forEach((key) => {
    stateClone.caps.entities[key]!.jsonIdd = "";
    stateClone.caps.entities[key]!.jsonData = "";
  });

  return stateClone;
}

const sentryMiddleware: Middleware = (
  store: MiddlewareAPI<Dispatch<Action>, IRootState>
) => {
  const initUser = getLSUser();

  Sentry.addGlobalEventProcessor((event) => {
    const state = compressState(store.getState());
    const usid = state.user.user?.usid ?? "User undefined?";

    return {
      ...event,
      extra: {
        ...event.extra,
        initUser,
        "redux:state": state,
      },
      user: {
        ...event.user,
        username: usid,
      },
    };
  });

  return (next: Dispatch<Action>) => (action: Action) => {
    Sentry.addBreadcrumb({
      category: "redux-action",
      message: action.type || "Nessun action.type -> BUG!",
    });

    return next(action);
  };
};

export default sentryMiddleware;
