import {schema} from "normalizr";
import {
  capSchema,
  ICapDB,
  ICapsEntities,
  IInvestmentCompany,
} from "../Caps/types";
import {entityUtilityFactory} from "../helpers/entityUtilityFactory";
import {parseISO} from "../types/dates";
import {IUserDB, IUsersEntities, userSchema} from "../Users/types";

export interface IElementaryClassDB {
  cap: ICapDB;
  company: IInvestmentCompany;
  contractor: IUserDB;
  date: string;
  dateSendEmail?: string;
  declarationConsistencyEsignId?: string;
  deliveryEsignId?: string;
  id: string;
  jsonDocs: string;
  jsonUser: string;
  profitCenter: string;
  status: ElementaryClassStatus;
  user: IUserDB;
  productsElementaryClass: {name: string; id: string};
}

export interface IElementaryClassEntity {
  cap: string;
  company: IInvestmentCompany;
  contractor: string;
  date: string;
  dateSendEmail: string;
  declarationConsistencyEsignId?: string;
  deliveryEsignId?: string;
  id: string;
  jsonDocs: string;
  jsonUser: string;
  profitCenter?: string;
  status: ElementaryClassStatus;
  user: string;
  productsElementaryClass: {name: string; id: string};
}

export interface IElementaryClass {
  cap: string;
  company: IInvestmentCompany;
  contractor: string;
  date: Date;
  dateSendEmail: Date;
  declarationConsistencyEsignId?: string;
  deliveryEsignId?: string;
  id: string;
  docs: IDocs;
  jsonUser: string;
  profitCenter?: string;
  status: ElementaryClassStatus;
  user: string;
  productsElementaryClass: {name: string; id: string};
}

interface IDocs {
  flagIsTcm: boolean;
  flagReadAllegato3: string;
  flagReadAllegato4Ter: string;
  flagReadAllegato4: string;
  flagReadAvvenutaConsegna: string;
  flagReadSetInformativo: string;
  flagReadDichiarazioneCoerenza: string;
}

export enum ElementaryClassStatus {
  Enabled = "attivo",
  Intermediate = "intermedio",
  Disabled = "inattivo",
}

export enum ElementaryClassClassFilter {
  ToBeSent = "ToBeSent",
  WaitingContractorESign = "WaitingContractorESign",
  ContractorESigned = "ContractorESigned",
}

export interface IElementaryClassParams {
  limit: number;
  offset: number;
  contractor?: string;
  date?: string;
  sort?: string;
  user?: string;
}

const elementaryClassSchema = new schema.Entity("elementaryClasses", {
  cap: capSchema,
  contractor: userSchema,
  user: userSchema,
});

export interface IElementaryClassesEntities {
  [key: string]: IElementaryClassEntity;
}

export type EntityShape = {
  elementaryClasses: IElementaryClassesEntities;
  caps: ICapsEntities;
  users: IUsersEntities;
};

const elementaryClassParse = (
  elementaryClassGeneric: IElementaryClassEntity
) => {
  const {date, dateSendEmail, jsonDocs, ...rest} = elementaryClassGeneric;
  const elementaryClass: IElementaryClass = {
    ...rest,
    date: parseISO(date),
    dateSendEmail: parseISO(dateSendEmail),
    docs: JSON.parse(jsonDocs) as IDocs,
  };

  return elementaryClass;
};

const elementaryClassestringify = (
  elementaryClassGeneric: IElementaryClass
) => {
  const {date, dateSendEmail, docs, ...rest} = elementaryClassGeneric;
  const elementaryClass: IElementaryClassEntity = {
    ...rest,
    date: date.toISOString(),
    dateSendEmail: dateSendEmail.toISOString(),
    jsonDocs: JSON.stringify(docs),
  };

  return elementaryClass;
};

export const elementaryClassEntityUtility = entityUtilityFactory<
  IElementaryClassEntity,
  IElementaryClassDB,
  IElementaryClass,
  EntityShape
>({
  schema: elementaryClassSchema,
  deserialize: elementaryClassParse,
  serialize: elementaryClassestringify,
});
