import {Component} from "react";
import {connect} from "react-redux";
import {NavLink as RRNavLink} from "react-router-dom";
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar as RSNavbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import {IRootState} from "../redux/reducers";
import NewTicketModal from "../Tickets/NewTicketModal";
import {actions as userActions} from "../User/actions";
import {IState as IUserState} from "../User/reducers";
import "./NavBar.scss";
import {actions, selectors} from "./slice";

interface IStateProps {
  user: IUserState;
  isOpen: boolean;
}
interface IDispatchProps {
  logoutClickHandler: () => Promise<void>;
  toggleMenu: () => void;
}

interface IState {
  isNewTicketOpen: boolean;
}

type IProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: IRootState) => ({
  user: state.user,
  isOpen: selectors.selectIsNavOpen(state),
});

const mapDispatchToProps = {
  logoutClickHandler: () => userActions.logout(),
  toggleMenu: () => actions.toggleNav(),
};

class Navbar extends Component<IProps, IState> {
  public state = {
    isNewTicketOpen: false,
  };

  public toggleNewTicket = () => {
    this.setState((prevState) => ({
      isNewTicketOpen: !prevState.isNewTicketOpen,
    }));
  };

  public render() {
    return (
      <RSNavbar expand="lg" className="mb-4" color="white" light fixed="top">
        <Container>
          <NavbarBrand tag={RRNavLink} to="/">
            <img
              src="/img/logo-header.svg"
              height="30"
              alt={`${process.env.REACT_APP_TITLE} logo`}
            />
          </NavbarBrand>
          <>
            <NavbarToggler onClick={this.props.toggleMenu} />
            <Collapse isOpen={this.props.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {this.props.user.permissions &&
                this.props.user.permissions.includes("cap.read") ? (
                  <NavItem>
                    <NavLink to="/analisi" tag={RRNavLink}>
                      Analisi
                    </NavLink>
                  </NavItem>
                ) : (
                  this.props.user.permissions &&
                  this.props.user.permissions.includes(
                    "cap.contractor-read"
                  ) && (
                    <NavItem>
                      <NavLink to="/analisi" tag={RRNavLink}>
                        Le tue analisi
                      </NavLink>
                    </NavItem>
                  )
                )}
                {this.props.user.permissions &&
                (this.props.user.permissions.includes("composition.read") ||
                  this.props.user.permissions.includes("compositions.read")) ? (
                  <NavItem>
                    <NavLink to="/asset-allocation" tag={RRNavLink}>
                      Raccomandazioni personalizzate
                    </NavLink>
                  </NavItem>
                ) : (
                  this.props.user.permissions &&
                  this.props.user.permissions.includes(
                    "composition.contractor-read"
                  ) && (
                    <NavItem>
                      <NavLink to="/asset-allocation" tag={RRNavLink}>
                        Le tue Raccomandazioni personalizzate
                      </NavLink>
                    </NavItem>
                  )
                )}
                {this.props.user.permissions &&
                this.props.user.userCompanies &&
                ((this.props.user.userCompanies.includes("99") &&
                  this.props.user.permissions.includes("proposal.read")) ||
                  this.props.user.permissions.includes("proposals.read")) ? (
                  <NavItem>
                    <NavLink to="/proposte" tag={RRNavLink}>
                      Proposte
                    </NavLink>
                  </NavItem>
                ) : (
                  this.props.user.permissions &&
                  this.props.user.permissions.includes(
                    "proposal.contractor-read"
                  ) && (
                    <NavItem>
                      <NavLink to="/proposte" tag={RRNavLink}>
                        Le tue proposte
                      </NavLink>
                    </NavItem>
                  )
                )}
                {this.props.user.permissions.includes("bene.manage") &&
                this.props.user.userCompanies.includes("101") ? (
                  <NavItem>
                    <NavLink to="/proposte-bene" tag={RRNavLink}>
                      Bene Assicurazioni
                    </NavLink>
                  </NavItem>
                ) : (
                  this.props.user.permissions.includes(
                    "bene.contractor-read"
                  ) && (
                    <NavItem>
                      <NavLink to="/proposte-bene" tag={RRNavLink}>
                        Bene Assicurazioni
                      </NavLink>
                    </NavItem>
                  )
                )}
                {this.props.user.permissions.includes(
                  "elementary_class.read"
                ) ||
                this.props.user.permissions.includes(
                  "elementary_classes.read"
                ) ? (
                  <NavItem>
                    <NavLink to="/tutele" tag={RRNavLink}>
                      Tutele
                    </NavLink>
                  </NavItem>
                ) : (
                  this.props.user.permissions.includes(
                    "elementary_class.contractor-read"
                  ) && (
                    <NavItem>
                      <NavLink to="/tutele" tag={RRNavLink}>
                        Le tue tutele
                      </NavLink>
                    </NavItem>
                  )
                )}
                {this.props.user.permissions &&
                  this.props.user.permissions.includes("users.manage") && (
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        Utenti
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem tag={RRNavLink} to="/users" exact>
                          Elenco utenti
                        </DropdownItem>
                        {/*<DropdownItem tag={RRNavLink} to="/users/create-bulk">*/}
                        {/*  Carica Advisor con CSV*/}
                        {/*</DropdownItem>*/}
                        {/*<DropdownItem tag={RRNavLink} to="/users/update-bulk">*/}
                        {/*  Modifica Advisor con CSV*/}
                        {/*</DropdownItem>*/}
                        {/*<DropdownItem tag={RRNavLink} to="/users/delete-bulk">*/}
                        {/*  Disattiva Advisor con CSV*/}
                        {/*</DropdownItem>*/}
                        <DropdownItem
                          href={`${process.env.REACT_APP_API_BASE_URL}users/download`}
                        >
                          Scarica lista Advisor
                        </DropdownItem>
                        <DropdownItem
                          href={`${process.env.REACT_APP_API_BASE_URL}users/disable-download`}
                        >
                          Scarica lista Advisor Inattivi
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                {this.props.user ? (
                  <>
                    {this.props.user.permissions?.includes("ticket.manage") && (
                      <>
                        <NavItem>
                          <NavLink href="#" onClick={this.toggleNewTicket}>
                            Segnalazione
                          </NavLink>
                        </NavItem>
                        <NewTicketModal
                          isOpen={this.state.isNewTicketOpen}
                          toggle={this.toggleNewTicket}
                        />
                      </>
                    )}
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        Il tuo account
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem tag={RRNavLink} to="/user/profile">
                          Gestione del profilo
                        </DropdownItem>
                        {this.props.user.permissions?.includes(
                          "cap.contractor-manage"
                        ) && (
                          <>
                            <DropdownItem tag={RRNavLink} to="/user/privacy">
                              Gestione del consenso
                            </DropdownItem>
                          </>
                        )}
                        {/*<DropdownItem*/}
                        {/*  tag={RRNavLink}*/}
                        {/*  to="/user/download-documents"*/}
                        {/*>*/}
                        {/*  I tuoi documenti*/}
                        {/*</DropdownItem>*/}
                        {/*<DropdownItem tag={RRNavLink} to="/user/signs">*/}
                        {/*  Gestione firme digitali*/}
                        {/*</DropdownItem>*/}
                        <DropdownItem onClick={this.props.logoutClickHandler}>
                          Esci dall'area riservata
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </>
                ) : (
                  <NavItem>
                    <NavLink to="/login" tag={RRNavLink}>
                      Entra nell'area riservata
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            </Collapse>
          </>
        </Container>
      </RSNavbar>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
