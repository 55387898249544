import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DeepReadonly} from "utility-types";
import {IChooseFundsFormData} from "../New/ChooseFundsForm";
import {ICompositionParams} from "../types";
import * as commonSelectors from "./common/selectors";
import {create, get, list, sendNotification} from "./common/thunks";

type IState = DeepReadonly<{
  chosenCap?: string;
  formNewWarn?: Partial<IChooseFundsFormData>;
  lastCreatedId?: string;
  list: string[];
  listLength: number;
  listParams: ICompositionParams;
}>;

const compositionsInitialState: IState = {
  list: [],
  listLength: 0,
  listParams: {limit: 50, offset: 0, sort: "-date"},
};

const common = createSlice({
  name: "compositions/common",
  initialState: compositionsInitialState,
  reducers: {
    choseCap: (state, action: PayloadAction<{capId: string | undefined}>) => {
      state.chosenCap = action.payload.capId;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.list = action.payload.compositionsIds;
        state.listLength = action.payload.numCompositions;
        state.listParams = action.payload.params;
      })
      .addCase(create.fulfilled, (state, action) => {
        state.lastCreatedId = action.payload.compositionId;
      })
      .addCase(create.reset, (state, action) => {
        delete state.lastCreatedId;
      });
  },
});

export const selectors = commonSelectors;

export const actions = {
  ...common.actions,
  create,
  get,
  list,
  sendNotification,
};
export const reducer = common.reducer;
