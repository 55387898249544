import {apiService} from "../App/service";
import IApiError from "../types/IApiError";
import {IChooseFundsFormData} from "./New/ChooseFundsForm";
import {ICompositionDB, ICompositionEntity, ICompositionParams} from "./types";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}compositions`;

interface CreateParams {
  jsonSelectedFunds: string;
  capId: string;
  userId: string;
  comment: string;
}
const compositionsService = {
  create(data: CreateParams) {
    return apiService.post(apiUrl, data).then((responseJson) => {
      return responseJson.composition as ICompositionDB;
    });
  },

  read(id: string) {
    return apiService
      .get(`${apiUrl}/${id}`)
      .then((responseJson) => responseJson.composition as ICompositionDB);
  },

  list(params: ICompositionParams) {
    return apiService.get(apiUrl, params).then((responseJson) => ({
      numCompositions: responseJson.numCompositions as number,
      compositionList: responseJson.compositions as ICompositionDB[],
    }));
  },

  update(id: string, compositions: Partial<ICompositionEntity>) {
    return apiService
      .put(`${apiUrl}/${id}`, compositions)
      .then((responseJson) => responseJson.composition as ICompositionDB);
  },

  delete(id: string) {
    return apiService.delete(`${apiUrl}/${id}`);
  },

  newFormWarn(values: Partial<IChooseFundsFormData>) {
    return apiService
      .post(`${apiUrl}/validate`, values)
      .then((responseJson) => responseJson.warn as IApiError);
  },

  sendNotification(id: string) {
    return apiService.get(`${apiUrl}/${id}/composition-notification`);
  },
};

export default compositionsService;
