import {lazy} from "@loadable/component";
import IRoutes from "../types/IRoutes";

const List = lazy(() => import(/* webpackChunkName: 'proposte' */ "./List"));
const New = lazy(() => import(/* webpackChunkName: 'proposte-new' */ "./New"));
const View = lazy(
  () => import(/* webpackChunkName: 'proposte-view' */ "./View")
);

const routes: IRoutes = [
  {
    component: List,
    exact: true,
    name: "composition",
    path: "/asset-allocation",
    permission: "composition.read||composition.contractor-read",
  },
  {
    component: New,
    exact: true,
    name: "composition-new",
    path: "/asset-allocation/new",
    permission: "composition.manage",
  },
  {
    component: View,
    exact: true,
    name: "composition-view",
    path: "/asset-allocation/:id?",
    permission: "composition.read||composition.contractor-read",
  },
];

export default routes;
