import {Dispatch} from "redux";
import IApiError from "../../types/IApiError";
import service from "../service";
import {IInvestmentFund} from "../types";

export enum ActionTypes {
  LOAD_FUNDS_REQUEST = "[Caps] Load funds request",
  LOAD_FUNDS_SUCCESS = "[Caps] Load funds success",
  LOAD_FUNDS_FAIL = "[Caps] Load funds fail",
}

interface ILoadFundsFailAction {
  type: typeof ActionTypes.LOAD_FUNDS_FAIL;
  payload: {error: IApiError};
}
interface ILoadFundsRequestAction {
  type: typeof ActionTypes.LOAD_FUNDS_REQUEST;
}
interface ILoadFundsSuccessAction {
  type: typeof ActionTypes.LOAD_FUNDS_SUCCESS;
  payload: {funds: IInvestmentFund[]};
}

export const actions = {
  loadFunds:
    (onlyAssociatedFunds: boolean = false) =>
    (dispatch: Dispatch<Actions>) => {
      dispatch(actions.loadFundsRequest());
      return service
        .loadFunds(onlyAssociatedFunds)
        .then((funds) => {
          dispatch(actions.loadFundsSuccess(funds));
        })
        .catch((error) => {
          dispatch(actions.loadFundsFail(error));
        });
    },
  loadFundsFail: (error: IApiError): ILoadFundsFailAction => ({
    payload: {error},
    type: ActionTypes.LOAD_FUNDS_FAIL,
  }),
  loadFundsRequest: (): ILoadFundsRequestAction => ({
    type: ActionTypes.LOAD_FUNDS_REQUEST,
  }),
  loadFundsSuccess: (funds: IInvestmentFund[]): ILoadFundsSuccessAction => {
    return {
      payload: {funds},
      type: ActionTypes.LOAD_FUNDS_SUCCESS,
    };
  },
};

export type Actions =
  | ILoadFundsFailAction
  | ILoadFundsRequestAction
  | ILoadFundsSuccessAction;
