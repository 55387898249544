import {createEntitySlice} from "../../helpers/createEntitySlice";
import {
  compositionEntityUtility,
  EntityShape,
  IComposition,
  ICompositionDB,
  ICompositionEntity,
} from "../types";

const entitySlice = createEntitySlice<
  ICompositionEntity,
  ICompositionDB,
  IComposition,
  EntityShape
>({
  entityUtility: compositionEntityUtility,
  nameSpace: "compositions/entities",
  selectState: (state: any) => state.compositions.entities,
});

export const {selectors, actions, reducer} = entitySlice;
